.main {
    width: 100%;
    background-color: var(--main-color);
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.nameContainer {
    width: 50%;
    display: flex;
    align-items: end;
    flex-direction: column;
    padding-right: 50px;
    box-sizing: border-box;
}

.name {
    font-family: var(--app-font);
    font-size: calc(var(--middle-text-size) + 8px);
    color: var(--font-color);
    text-align: right;
}

.position {
    font-family: var(--app-font);
    font-size: var(--middle-text-size);
    color: var(--accent-color);
}

.rightSide {
    display: flex;
    width: 50%;
}

.contacts {
    width: 80%;
    display: flex;
}

.contactName {
    font-family: var(--app-font);
    font-size: calc(var(--middle-text-size) - 2px);
    color: var(--font-color);
    opacity: .6;
}

.contactValue {
    font-family: Calibri, serif;
    font-size: var(--middle-text-size);
    color: var(--font-color);
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.magicContainer {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.magic {
    height: 15px;
}

@media (max-width: 800px) {
    .main {
        flex-direction: column;
    }

    .nameContainer {
        width: 100%;
        align-items: center;
        flex-direction: column;
        padding-right: 0;
        margin-bottom: 20px;
    }

    .name {
        text-align: center;
    }

    .rightSide {
        width: 100%;
        flex-direction: column;
    }

    .contacts {
        width: 100%;
    }

    .magicContainer {
        width: 100%;
    }

    .magic {
        margin-top: 10px;
    }
}