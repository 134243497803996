.main {
    background-color: var(--main-color);
    height: 200px;
    width: 280px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    border-radius: 10px;
    padding: 30px 20px 20px;
    transition: .3s;
}

.title {
    color: var(--accent-color);
    font-family: var(--app-font);
    font-size: calc(var(--middle-text-size) + 6px);
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20%;
    text-align: center;
    opacity: .9;
    transition: .3s;
}

.text {
    color: var(--font-color);
    font-family: var(--app-font);
    font-size: var(--middle-text-size);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80%;
    opacity: .9;
    transition: .3s;
}

.main:hover {
    background-color: var(--element-color);
}

.main:hover .title{
    opacity: 1;
    transform: translateY(-3px);
}

.main:hover .text{
    opacity: 1;
    transform: translateY(-3px);
}