.main {
    height: 100%;
    width: 100%;
    display: flex;
}

.buttonLeft {
    height: 100%;
    width: 45%;
    margin-right: auto;
    background-color: var(--element-color);
    transition: .3s;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonLeft:hover {
    transform: translateY(-3px) translateX(-3px);
    box-shadow: 3px 3px 3px var(--shadow-color);
}

.buttonRight {
    height: 100%;
    width: 45%;
    margin-left: auto;
    background-color: var(--element-color);
    transition: .3s;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonRight:hover {
    transform: translateY(-3px) translateX(-3px);
    box-shadow: 3px 3px 3px var(--shadow-color);
}

.arrowContainer {
    height: 40px;
    width: 40px;
    position: relative;
}

.leftArrow {
    height: 20px;
    width: 20px;
    border: 4px solid var(--font-color);
    border-bottom-style: none;
    border-right-style: none;
    transform: rotate(-45deg);
    position: absolute;
    top: 8px;
    left: 12px;
    transition: .3s;
}

.buttonLeft:hover .leftArrow {
    border: 4px solid var(--accent-color);
    border-bottom-style: none;
    border-right-style: none;
}

.rightArrow {
    height: 20px;
    width: 20px;
    border: 4px solid var(--font-color);
    border-bottom-style: none;
    border-right-style: none;
    transform: rotate(135deg);
    position: absolute;
    top: 8px;
    left: 3px;
    transition: .3s;
}

.buttonRight:hover .rightArrow {
    border: 4px solid var(--accent-color);
    border-bottom-style: none;
    border-right-style: none;
}

@media (max-width: 800px) {
    .buttonLeft:hover {
        transform: none;
        box-shadow: none;
    }

    .buttonRight:hover {
        transform: none;
        box-shadow: none;
    }

    .buttonLeft:hover .leftArrow {
        border: 4px solid var(--font-color);
        border-bottom-style: none;
        border-right-style: none;
    }

    .buttonRight:hover .rightArrow {
        border: 4px solid var(--font-color);
        border-bottom-style: none;
        border-right-style: none;
    }

    .buttonLeft:active .leftArrow {
        border: 4px solid var(--accent-color);
        border-bottom-style: none;
        border-right-style: none;
    }

    .buttonRight:active .rightArrow {
        border: 4px solid var(--accent-color);
        border-bottom-style: none;
        border-right-style: none;
    }
}