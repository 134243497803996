@import url('https://fonts.googleapis.com/css2?family=Finlandica:wght@400;600&display=swap');

:root {
    --main-color: #212428;
    --rgb-main-color: 33, 36, 40;
    --element-color: #18191d;
    --shadow-color: #1a1c20;
    --accent-color: #ff014e;
    --font-color: #fff;
    --app-font: 'Finlandica', sans-serif;
    --big-text-size: 50px;
    --middle-text-size: 18px;
}

.backgroundImage {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: url("images/buisinesPeople.jpg");
    background-size: cover;
}

.backgroundShadow {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: var(--main-color);
    opacity: .6;
}

.scrollPlace {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;
}

.scrollPlace::-webkit-scrollbar {
    width: 0;
    height: 0;
}

@media (max-width: 600px) {
    :root {
        --big-text-size: 35px;
    }
}

.alert-enter {
    opacity: 0;
    transform: scale(0.9);
}
.alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}
.alert-exit {
    opacity: 1;
}
.alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}