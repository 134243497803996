.main {
    width: 280px;
    height: 350px;
    background-color: var(--main-color);
    border-radius: 10px;
    margin: 10px;
    padding-bottom: 10px;
    box-sizing:border-box;
    transition: .3s;
}

.contentContainer {
    height: 100%;
    width: 100%;
    opacity: .9;
    transition: .3s;
}

.main:hover {
    background-color: var(--element-color);
}

.main:hover .contentContainer {
    opacity: 1;
    transform: translateY(-3px);
}

.iconContainer {
    height: 20%;
    width: 100%;
    display: flex;
    align-items: center;
}

.icon {
    height: 80%;
    margin-left: 10px;
}

.serviceName {
    height: 20%;
    width: 100%;
    box-sizing:border-box;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    font-family: var(--app-font);
    color: var(--font-color);
    font-size: calc(var(--middle-text-size) + 3px);
    font-weight: bolder;
}

.serviceDescription {
    height: 60%;
    width: 100%;
    font-family: var(--app-font);
    font-size: var(--middle-text-size);
    color: var(--font-color);
    padding: 10px;
    box-sizing:border-box;
    overflow-y: scroll;
    overflow-x: hidden;
    white-space: break-spaces;
}

.serviceDescription::-webkit-scrollbar {
    width: 0;
    height: 0;
    white-space: break-spaces;
}