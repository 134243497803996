.main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.smallInscription {
    font-family: var(--app-font);
    font-size: var(--middle-text-size);
    color: var(--accent-color);
    margin-top: 20px;
}

.bigInscription {
    font-family: var(--app-font);
    font-size: var(--big-text-size);
    color: var(--font-color);
    font-weight: bold;
    margin-bottom: 20px;
    text-shadow: 1px 1px 3px var(--shadow-color);
}

.servicesPlace {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}